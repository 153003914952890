import Link from 'next/link';
import React, { useEffect, useState } from 'react';

export default function FloatingBanner(props) {

  const [isPopupShow, setPopupShow] = useState(false);

  const closePopup = (expireDays) => {
    let expire = new Date();
    expire.setTime(expire.getTime() + (expireDays * 24 * 60 * 60 * 1000));
    localStorage.setItem('MOBILE_FLOATING_BANNER', expire.getTime());
  }

  const checkPopupClose = () => {
    const expireDay = localStorage.getItem("MOBILE_FLOATING_BANNER");

    if (!expireDay) { // 만약 localStorage에 값이 없다면 팝업을 보여주어야 합니다.
      return false;
    }

    let today = new Date();

    if (today.getTime() > expireDay) {
      return false;
    } else {
      return true;
    }
  }

  const closePopupToday = () => {
    document.body.classList.remove('noScroll');
    closePopup(1);
    setPopupShow(false);
  };

  useEffect(() => {
    if (props.m_view_yn === 'Y') {
      if (checkPopupClose()) {
        document.body.classList.remove('noScroll');
        setPopupShow(false);
      } else {
        document.body.classList.add('noScroll');
        setPopupShow(true);
      }
    }
  }, []);
  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를

  const handleClick_custom = (a,b,c,d) => {

    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '빅배너',
        clickType: 'NC',
        value1: props.pc_img_attr,
        value2: '',
        value3: '',
        value4: '',
        value5: ''
      }
    });

  };


  return (
    <>
      {/*<!-- 최상단배너 -->*/}
      {isPopupShow && (
        <div className={`topEventBanner_w ${props.m_view_yn === 'Y' ? 'current' : ''}`}>
          {/*<!-- current 추가시 노출됨 -->*/}
          <div className="topEventBanner_inner" style={props.bg_color_yn === 'Y' ? { backgroundColor: props.bg_color_code } : {}}>
            <Link href={props.link_url} >
              <a  onClick={(e) => handleClick_custom(e, '','0','all')}  target={props.link_new_yn === 'Y' ? '_blank' : ''} className="topEventBanner_link"
              ><img src={props.m_img_url} alt={props.m_img_attr}
                /></a>
            </Link>
            <div className="topEventBnrBtns_w">
              <button type="button" className="topEventBnrBtn_todayClosed" onClick={closePopupToday}>
                하루 동안 보지 않기
              </button>
              <button
                type="button"
                className="topEventBnrBtn_closed"
                onClick={() => {
                  document.body.classList.remove('noScroll');
                  localStorage.removeItem('MOBILE_FLOATING_BANNER'); // Here is the addition
                  setPopupShow(false);
                }}
              >
                <span className="iconSvg_closed"><i className="hide">배너닫기</i></span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}


