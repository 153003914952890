export default [
  {
    code: '',
    text: '전체',
    link: '',
    sortType: 'all',
    category: [],
    api: { pgmSct: '', subPath: { all: 'tv', category: 'tv_thema' } }
  },
  {
    code: 'drama',
    text: '드라마',
    link: '/tv/drama',
    sortType: 'normal',
    category: [{ code: '', text: '전체 장르', link: { outUrl: '', query: { category: '' } }, relatedCategory: [] },
      { code: 'romantic', text: '로맨스', link: { outUrl: '', query: { category: 'romantic' } }, relatedCategory: ['romantic'] },
      { code: 'melo', text: '멜로', link: { outUrl: '', query: { category: 'melo' } }, relatedCategory: ['melo'] },
      { code: 'historical', text: '사극 · 시대극', link: { outUrl: '', query: { category: 'historical' } }, relatedCategory: ['historical', 'modern'] },
      { code: 'legal', text: '법정 · 범죄수사', link: { outUrl: '', query: { category: 'legal' } }, relatedCategory: ['legal', 'crime'] },
      { code: 'action', text: '액션', link: { outUrl: '', query: { category: 'action' } }, relatedCategory: ['action'] },
      { code: 'fantasy', text: '판타지 SF', link: { outUrl: '', query: { category: 'fantasy' } }, relatedCategory: ['fantasy'] },
      { code: 'political', text: '정치 · 경제 · 의학', link: { outUrl: '', query: { category: 'political' } }, relatedCategory: ['political', 'finance', 'medical'] },
      { code: 'mystery', text: '미스터리 · 스릴러', link: { outUrl: '', query: { category: 'mystery' } }, relatedCategory: ['mystery', 'thriller'] },
      { code: 'family', text: '가족 · 청춘 · 성장', link: { outUrl: '', query: { category: 'family' } }, relatedCategory: ['family', 'teenage', 'growth'] },
      { code: 'sitcom', text: '시트콤 · 코미디', link: { outUrl: '', query: { category: 'sitcom' } }, relatedCategory: ['sitcom', 'comedy'] },
      { code: 'awards', text: '시상식', link: { outUrl: '', query: { category: 'awards' } }, relatedCategory: ['awards'] }
    ],
    api: { pgmSct: 'DR', subPath: { all: 'tv', category: 'tv_thema' } }
  },
  {
    code: 'enter',
    text: '예능',
    link: '/tv/enter',
    sortType: 'normal',
    category: [{ code: '', text: '전체 장르', link: { outUrl: '', query: { category: '' } }, relatedCategory: [] },
      { code: 'realvariety', text: '리얼리티', link: { outUrl: '', query: { category: 'realvariety' } }, relatedCategory: ['realvariety', 'observation'] },
      { code: 'music', text: '뮤직', link: { outUrl: '', query: { category: 'music' } }, relatedCategory: ['music'] },
      { code: 'audition', text: '오디션 · 서바이벌', link: { outUrl: '', query: { category: 'audition' } }, relatedCategory: ['audition', 'survival'] },
      { code: 'foods', text: '푸드', link: { outUrl: '', query: { category: 'foods' } }, relatedCategory: ['foods'] },
      { code: 'game', text: '게임 · 퀴즈', link: { outUrl: '', query: { category: 'game' } }, relatedCategory: ['game', 'quiz'] },
      { code: 'comedy', text: '코미디 · 토크', link: { outUrl: '', query: { category: 'comedy' } }, relatedCategory: ['comedy', 'talk'] },
      { code: 'entertaininfo', text: '연예정보 · 영화정보', link: { outUrl: '', query: { category: 'entertaininfo' } }, relatedCategory: ['entertaininfo', 'movieinfo'] },
      { code: 'awards', text: '시상식 · 콘서트', link: { outUrl: '', query: { category: 'awards' } }, relatedCategory: ['awards', 'concert'] }
    ],
    api: { pgmSct: 'ET', subPath: { all: 'tv', category: 'tv_thema' } }
  },
  {
    code: 'culture',
    text: '교양',
    link: '/tv/culture',
    sortType: 'normal',
    category: [{ code: '', text: '전체 장르', link: { outUrl: '', query: { category: '' } }, relatedCategory: [] },
      { code: 'Investigative', text: '탐사 · 이슈스토리', link: { outUrl: '', query: { category: 'Investigative' } }, relatedCategory: ['Investigative', 'issuestory'] },
      { code: 'lifeinfo', text: '생활정보', link: { outUrl: '', query: { category: 'lifeinfo' } }, relatedCategory: ['lifeinfo', 'lifeinfo'] },
      { code: 'nature', text: '자연 · 동물', link: { outUrl: '', query: { category: 'nature' } }, relatedCategory: ['nature', 'animal'] },
      { code: 'humanity', text: '휴머니티', link: { outUrl: '', query: { category: 'humanity' } }, relatedCategory: ['humanity'] },
      { code: 'culture', text: '문화 · 예술', link: { outUrl: '', query: { category: 'culture' } }, relatedCategory: ['culture', 'art'] },
      { code: 'currentaffairs', text: '시사 · 경제 · 토론', link: { outUrl: '', query: { category: 'currentaffairs' } }, relatedCategory: ['currentaffairs', 'finance', 'discussion'] },
      { code: 'docu', text: '다큐 · 스페셜', link: { outUrl: '', query: { category: 'docu' } }, relatedCategory: ['docu', 'special'] },
      { code: 'kids', text: '키즈 · 애니메이션', link: { outUrl: '', query: { category: 'kids' } }, relatedCategory: ['kids', 'animation'] },
      { code: 'campaign', text: '캠페인 · 환경', link: { outUrl: '', query: { category: 'campaign' } }, relatedCategory: ['campaign', 'environment'] }
    ],
    api: { pgmSct: 'CU', subPath: { all: 'tv', category: 'tv_thema' } }
  },
  {
    code: 'sports',
    text: '스포츠',
    link: '/tv/sports',
    sortType: 'normal',
    category: [{ code: '', text: '전체 장르', link: { outUrl: '', query: { category: '' } }, relatedCategory: [] },
      { code: 'baseball', text: '야구', link: { outUrl: '', query: { category: 'baseball' } }, relatedCategory: ['baseball'] },
      { code: 'soccer', text: '축구', link: { outUrl: '', query: { category: 'soccer' } }, relatedCategory: ['soccer'] },
      { code: 'volleyball', text: '배구', link: { outUrl: '', query: { category: 'volleyball' } }, relatedCategory: ['volleyball'] },
      { code: 'wintersports', text: '동계', link: { outUrl: '', query: { category: 'wintersports' } }, relatedCategory: ['wintersports'] },
      { code: 'totalsports', text: '종합', link: { outUrl: '', query: { category: 'totalsports' } }, relatedCategory: ['totalsports'] }
    ],
    api: { pgmSct: 'SP', subPath: { all: 'tv', category: 'tv_thema' } }
  },
  {
    code: 'free',
    text: '무료',
    link: '/tv/free',
    sortType: 'free',
    category: [{ code: '', text: '전체 장르', link: { outUrl: '', query: { category: '' } }, relatedCategory: [] },
      { code: 'drama', pgmSct: 'DR', text: '드라마', link: { outUrl: '', query: { category: 'drama' } }, relatedCategory: [] },
      { code: 'enter', pgmSct: 'ET', text: '예능', link: { outUrl: '', query: { category: 'enter' } }, relatedCategory: [] },
      { code: 'culture', pgmSct: 'CU', text: '교양', link: { outUrl: '', query: { category: 'culture' } }, relatedCategory: [] },
      { code: 'sports', pgmSct: 'SP', text: '스포츠', link: { outUrl: '', query: { category: 'sports' } }, relatedCategory: [] }
      // { code: 'original', pgmSct: 'DI', text: '디지털 오리지널', link: { outUrl: '', query: { category: 'original' } }, relatedCategory: [] }
    ],
    api: { pgmSct: '', subPath: { all: 'free_program', category: 'free_program' } }
  }
];
