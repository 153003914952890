// isSkipParam: true -> api의 조회 문자열에 포함 하지 않는다.

export default {
  all: {
    searchParamKey: 'sort',
    searchParamList: [
      { code: 'new', text: '최신', link: 'new', isSkipParam: false },
      { code: 'top', text: '인기', link: 'top', isSkipParam: false },
      { code: 'abc', text: '가나다', link: 'abc', isSkipParam: false },
      { code: 'theme', text: '추천', link: 'theme', isSkipParam: false }
    ]
  },
  normal: {
    searchParamKey: 'sort',
    searchParamList: [
      { code: 'new', text: '최신', link: 'new', isSkipParam: false },
      { code: 'top', text: '인기', link: 'top', isSkipParam: false },
      { code: 'abc', text: '가나다', link: 'abc', isSkipParam: false }
    ]
  },
  free: {
    searchParamKey: 'sort',
    searchParamList: [
      { code: 'top', text: '인기', link: 'top', isSkipParam: false },
      { code: 'new', text: '최신', link: 'new', isSkipParam: false },
      { code: 'abc', text: '가나다', link: 'abc', isSkipParam: false }
    ]
  },
  boradTime: {
    searchParamKey: 'brd_state',
    searchParamList: [
      { code: 'all', text: '전체', link: 'all', isSkipParam: true },
      { code: 'N', text: '방영 예정', link: 'tobe', isSkipParam: false },
      { code: 'Y', text: '방영 중', link: 'ing', isSkipParam: false }
    ]
  }
};
