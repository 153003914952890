export default [
  [
    {
      sectionTitle: '라이브',
      sectionList: [{ title: '전체', link: '/live?div=sitemap_pc' },
        { title: 'SBS TV', link: '/live/S01?div=sitemap_pc' },
        { title: '파워 FM', link: '/live/S07?div=sitemap_pc' },
        { title: '러브 FM', link: '/live/S08?div=sitemap_pc' },
        { title: 'SBS Plus', link: '/live/S03?div=sitemap_pc' },
        { title: 'SBS FunE', link: '/live/S04?div=sitemap_pc' },
        { title: 'SBS Sports', link: '/live/S02?div=sitemap_pc' },
        { title: 'SBS Golf', link: '/live/S05?div=sitemap_pc' },
        { title: 'SBS MTV', link: '/live/S09?div=sitemap_pc' },
        { title: 'SBS Biz', link: '/live/S06?div=sitemap_pc' }
      ]
    },
    {
      sectionTitle: 'TV',
      sectionList: [{ title: 'TV 전체', link: '/tv?div=sitemap_pc' },
        { title: '드라마', link: '/tv/drama?div=sitemap_pc' },
        { title: '예능', link: '/tv/enter?div=sitemap_pc' },
        { title: '교양', link: '/tv/culture?div=sitemap_pc' },
        { title: '스포츠', link: '/tv/sports?div=sitemap_pc' },
        { title: '무료', link: '/tv/free?div=sitemap_pc' }
      ]
    },
    {
      sectionTitle: '라디오',
      sectionList: [{ title: '라디오 홈', link: 'https://www.sbs.co.kr/radio?div=sitemap_pc' },
        { title: 'SBS 고릴라 소개', link: 'https://www.sbs.co.kr/radio/gorealra.html?div=sitemap_pc' },
        { title: '팟캐스트 통계', link: 'https://cloud.radio.sbs.co.kr/iab.html?div=sitemap_pc' },
        { title: '전국 주파수 안내', link: 'https://www.sbs.co.kr/radio/frequency.html?div=sitemap_pc' },
        { title: '라디오 기프트몰', link: 'https://radiogiftmall.sbs.co.kr/?div=sitemap_pc' },
        { title: '라디오 공지사항', link: 'https://www.sbs.co.kr/tv/radio/radioNotice.html?div=sitemap_pc' }
      ]
    },
    {
      sectionTitle: '뉴스',
      sectionList: [{ title: '뉴스 홈', link: 'https://news.sbs.co.kr/news/newsMain.do?div=sitemap_pc' },
        { title: '분야별', link: 'https://news.sbs.co.kr/news/newsflash.do?div=sitemap_pc' },
        { title: '다시보기', link: 'https://news.sbs.co.kr/news/newsflash.do?&div=sitemap_pc' },
        { title: '취재파일', link: 'https://news.sbs.co.kr/news/coverageList.do?div=sitemap_pc' },
        { title: '팟캐스트', link: 'https://news.sbs.co.kr/news/podcastList.do?div=sitemap_pc' },
        { title: '멀티미디어', link: 'https://news.sbs.co.kr/news/newsPlus.do?div=sitemap_pc' },
        { title: '비디오머그', link: 'https://news.sbs.co.kr/news/videoMugList.do?div=sitemap_pc' },
        { title: '스브스뉴스', link: 'https://news.sbs.co.kr/news/subusuNews.do?div=sitemap_pc' }
      ]
    }
  ],
  [
    {
      sectionTitle: '회원',
      sectionList: [{ title: '마이존', link: 'http://w3.sbs.co.kr/myzone/myzoneMain.do?div=sitemap_pc' },
        { title: '회원가입', link: 'https://join.sbs.co.kr/join/join.do?div=sitemap_pc' },
        { title: '아이디 찾기', link: 'https://join.sbs.co.kr/help/find_id.do?div=sitemap_pc' },
        { title: '비밀번호 재설정', link: 'https://join.sbs.co.kr/help/find_pwd.do?div=sitemap_pc' },
        { title: '개인정보 수정', link: 'https://join.sbs.co.kr/myzone/myzoneUserInfo.do?menuid=0400&div=sitemap_pc' },
        { title: '회원탈퇴', link: 'https://join.sbs.co.kr/myzone/myzoneUserInfo.do?menuid=0405&div=sitemap_pc' }
      ]
    },
    {
      sectionTitle: '고객센터',
      sectionList: [{ title: '공지사항', link: 'http://w3.sbs.co.kr/cs/customerNoticeNew.do?div=sitemap_pc' },
        { title: '자주 찾는 질문', link: 'http://w3.sbs.co.kr/cs/faq.do?all=all&div=sitemap_pc' },
        { title: '1:1 웹메일 문의', link: 'https://member.sbs.co.kr/member/cs/email.do?div=sitemap_pc' },
        { title: '이용약관', link: 'http://w3.sbs.co.kr/cs/customerRulesSbs.do?div=sitemap_pc' },
        { title: '개인정보 처리방침', link: 'http://w3.sbs.co.kr/cs/customerPrivacySbs.do?div=sitemap_pc' }
      ]
    },
    {
      sectionTitle: '모바일 앱',
      sectionList: [{ title: 'SBS ', link: 'https://about.sbs.co.kr/platform' },
        { title: 'SBS 고릴라', link: 'https://www.sbs.co.kr/radio/gorealra.html??div=sitemap_pc' },
        { title: 'SBS 뉴스', link: 'https://news.sbs.co.kr/news/app.do?div=sitemap_pc' }
      ]
    },
    {
      sectionTitle: '서비스',
      sectionList: [
        { title: '플레이존', link: 'https://www.sbs.co.kr/playzone?div=sitemap_pc'},
        { title: '플레이톡', link: 'https://www.sbs.co.kr/playtalk?div=sitemap_pc'},
        { title: '편성표', link: 'https://www.sbs.co.kr/schedule/index.html?div=sitemap_pc' },
        { title: '이용권', link: 'http://w3.sbs.co.kr/pass/pass.do?menuType=ticket&div=sitemap_pc' },
        { title: 'STAR', link: 'https://star.sbs.co.kr/?div=sitemap_pc' },
        { title: 'TV맛집', link: 'http://matstar.sbs.co.kr/main.html?div=sitemap_pc' },
        { title: 'ALLVOD', link: 'http://allvod.sbs.co.kr/allvod/vodMain.do?div=sitemap_pc' },
        { title: 'PD노트', link: 'https://programs.sbs.co.kr/special/pdnote/multiboards/65942?div=sitemap_pc' }
      ]
    }
  ]
];
