import sectionInfo from './sectionInfo';
import gnbId from './gnbId';
import language from './language';
import srsDiv from './srsDiv';
import tvSectionList from './tv/sectionList';
// import tvSectionMTestList from './tv/sectionList'; // TODO childmild 모바일 테스트중 테스트 끝나고 지울것
import sectionCategorySorts from './tv/sectionCategorySorts';
import sitemap from './sitemap';

const DEFAULT_SECTION_CODE = '';

export {
  sectionInfo,
  gnbId,
  language,
  srsDiv,
  tvSectionList,
  sectionCategorySorts,
  sitemap,
  DEFAULT_SECTION_CODE
};
