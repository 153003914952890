import mobile from './mobile';
import pc from './pc';
import { sectionInfo } from '../../constants';
import generateTranslate from './generateTranslate';

/**
 * PC/MOBILE Gnb생성
 * @param {Object} GnbProperty - GNB에서 노출될 속성
 * @param {boolean} GnbProperty.isMobile - PC/MOBILE여부(true: MOBILE, false: PC)
 * @param {string} GnbProperty.section - GNB상단 섹션('tv', 'live', 'news', 'radio', 'star', 'event')
 * @param {string} GnbProperty.text - (MOBILE만 사용) 상단에 노출될 Title 요소
 * @param {string} GnbProperty.link - (MOBILE만 사용) 상단에 Title 탭시 이동할 link
 */
export default async function gnbGenerater ({ isMobile, pathname, router, isGnbYn = true, render }) {
  let gnbObject;

  const sectionId = (pathname || '').replace('/m', '').split('/').filter(x => !!x)[0] || '';
  const sectionData = sectionInfo(sectionId);
  const search = !pathname.includes('/promotion');
  //console.log('sectionData :>> ', sectionData);
  if (isMobile) {
    gnbObject = await mobile({ ...sectionData, search, render });
  } else {
    gnbObject = await pc({ ...sectionData });
  }

  if (isGnbYn) {
    generateTranslate(router, gnbObject);
  }

  return gnbObject;
}
